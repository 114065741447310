import ahoy from 'shared/ahoy'

export const GRAPHQL_ENDPOINT = `${import.meta.env.VITE_API_HOST}/graphql`
export const AUTHORIZATION = `Bearer ${import.meta.env.VITE_API_AUTH_TOKEN}`
export const DEFAULT_HEADERS = () => {
  return {
    'Ahoy-Visit': ahoy.getVisitId(),
    'Ahoy-Visitor': ahoy.getVisitorId(),
    'Authorization': AUTHORIZATION,
    'Content-Type': 'application/json'
  }
}

export const graphqlQuery = async ({ query, variables, headers }) => {
  const response = await fetch(GRAPHQL_ENDPOINT, {
    method: 'POST',
    credentials: 'include',
    headers: {
      ...DEFAULT_HEADERS.call(),
      ...headers
    },
    body: JSON.stringify({
      query,
      variables
    })
  })
  return await response.json()
}
