// TODO refactor this massive function
const AVAILABLE_SIZES = {
  storage: ['10', '20', '40', '45'],
  shipping: ['20', '40', '45'],
  refrigarated: ['20', '40']
}
const SIZES = {
  allSizes: {
    '10': '10’ Container',
    '20': '20’ Container',
    '40': '40’ Container',
    '45': '45’ Container'
  },
  standard: {
    '10': '10 Foot',
    '20': '20 Foot',
    '24': '24 Foot',
    '40': '40 Foot',
    '45': '45 Foot'
  },
  highCube: {
    '20HC': '20 Foot High Cube',
    '40HC': '40 Foot High Cube',
    '45HC': '45 Foot High Cube',
    '53HC': '53 Foot High Cube'
  }
}

const SHIPPING_CONDITIONS = [
  'one_trip',
  'iicl',
  'cargo_worthy'
]

const CONDITIONS = {
  'one_trip': 'One Trip',
  'refurbished': 'Refurbished',
  'cargo_worthy': 'Cargo Worthy',
  'wind_water_tight': 'Wind & Water Tight'   
}

const initOptions = () => {
  return {
    purchaseType: {
      rental: 'Rental',
      sale: 'Purchase'
    },
    useCase: {
      storage: 'Container Storage',
      shipping: 'Shipping Export',
      coldStorage: 'Refrigerated Container Storage',
      insulatedStorage: 'Insulated Container Storage'
      //other: 'Other / Unsure'
      // shipping: 'Shipping',
      //coldStorage: 'Refrigerated Storage',
      //dryStorage: 'Insulated Storage',
      // other: 'Other / Unsure'
    },
    size: {
      standard: {},
      highCube: {},
      allSizes: {}
    },
    // conditionCost: {
    //   cost: [1, 2, 3, 4, 5],
    //   cosmetic: [1, 2, 3, 4, 5],
    //   longevity: [1, 2, 3, 4, 5]
    // },
    condition: {},
    options: true
  }
}

const shippingAvailable = (priceBookEntries) => {
  if (priceBookEntries.length === 0) return true
 
  return priceBookEntries.filter(i => {
    if (!i.product.size) return false
    const numberPart = i.product.size.replace(/\D/g,'')    
    if (SHIPPING_CONDITIONS.includes(i.product.condition) && AVAILABLE_SIZES.shipping.includes(numberPart)) {
      return true
    }
    return false
  }).length > 0
}

const storageAvailable = (priceBookEntries) => {
  if (priceBookEntries.length === 0) return true
  return priceBookEntries.filter(i => {
    if (!i.product.size) return false
    const numberPart = i.product.size.replace(/\D/g,'')    
    if (AVAILABLE_SIZES.storage.includes(numberPart)) {
      return true
    }
    return false
  }).length > 0
}

const rentalsAvailable = (priceBookEntries) => {
  if (priceBookEntries.length === 0) return true
  return priceBookEntries.filter(i => i.product.classification === 'container_rental').length > 0
}
const refrigeratesAvailable = (priceBookEntries, type = 'refrigerated') => {
  if (priceBookEntries.length === 0) return true
  return priceBookEntries.filter(i => {
    if (!i.product.size) return false
    const numberPart = i.product.size.replace(/\D/g,'')    
    if (i.product.config && i.product.config.includes('refrigerated') && i.product.config == type && AVAILABLE_SIZES.refrigarated.includes(numberPart)) {
      return true
    }
    return false
  }).length > 0  
}

const removeOtherConditions = (priceBookEntries) => {
  if (priceBookEntries.length === 0) return priceBookEntries
  return priceBookEntries.filter(i => CONDITIONS[i.product.condition])
}


const filterOptions = (items, option, value) => items.filter(i => i.product[option] && i.product[option] === value)

export default ({ lead, priceBook, commit }) => {

  const options = initOptions()
  let priceBookEntries = []
  let originalPriceBookEntries = []

  if (!lead) {
    return {
      options,
      priceBookEntries
    }
  }
  const { purchaseType, size, useCase, condition } = lead.data
  // Map all products from price book. This will determine answers for the rest of the questions.
  if (priceBook) {
    priceBookEntries = priceBook.entries.sort((a, b) => a.product.code.localeCompare(b.product.code))
    originalPriceBookEntries = [...priceBookEntries]
  }

  if (!rentalsAvailable(priceBookEntries)) {
    delete options.purchaseType
    priceBookEntries = priceBookEntries.filter(i => i.product.classification === 'container')
    priceBookEntries = removeOtherConditions(priceBookEntries)    
    commit('quote/lead.options.update', { purchaseType: 'sale' }, { root: true })
  }

  if (!storageAvailable(priceBookEntries)) {
    delete options.useCase.storage
  }

  if (!shippingAvailable(priceBookEntries)) {
    delete options.useCase.shipping
  }

  // Filter by product classification
  if (typeof purchaseType === 'string') {
    let classification = purchaseType === 'sale' ? 'container' : 'container_rental'
    priceBookEntries = priceBookEntries.filter(i => i.product.classification === classification)
    if (purchaseType === 'sale') {
      priceBookEntries = removeOtherConditions(priceBookEntries)
    }
  }

  if (!refrigeratesAvailable(priceBookEntries, 'working_refrigerated')) {
    delete options.useCase.coldStorage
  }

  if (!refrigeratesAvailable(priceBookEntries, 'non_working_refrigerated')) {
    delete options.useCase.insulatedStorage
  }
  
  // console.log('[debug] zip:', lead.zipcode, 'type:', purchaseType, 'useCase:', useCase, 'size:', size, 'condition:', condition)

  if (purchaseType === 'rental') {
    // Remove condition step and shipping if rental
    commit('quote/lead.options.update', { condition: 'other' }, { root: true })
    delete options.condition
    delete options.useCase.shipping
  } else {
    /* Add condition and shipping if the user changes the filter on 'Option screen' and click to 
    go back */
    options.condition = {}
    if (shippingAvailable(priceBookEntries)) {
      options.useCase.shipping = 'Shipping Export'
    }    
  }

  // Filter by config
  if (useCase) {
    switch (useCase) {
    case 'coldStorage':
      priceBookEntries = filterOptions(priceBookEntries, 'config', 'working_refrigerated')
      break
    case 'insulatedStorage':
      priceBookEntries = filterOptions(priceBookEntries, 'config', 'non_working_refrigerated')
      break
    case 'shipping':
      priceBookEntries = priceBookEntries.filter(i => SHIPPING_CONDITIONS.includes(i.product.condition) && !i.product?.config?.includes('refrigerated'))
      break
    case 'storage': 
      priceBookEntries = priceBookEntries.filter(i => !i.product?.config?.includes('refrigerated'))
      break
    }
  }

  // Add available sizes, or default to all
  if (priceBookEntries.length) {
    const sizes = Array.from(new Set(priceBookEntries.filter(i => i.product.size && i.product.size !== 'ODD').map(i => i.product.size)))
    
    if (useCase) {
      sizes.forEach(i => {
        const numberPart = i.replace(/\D/g,'')    
        if (SIZES.allSizes[numberPart] && AVAILABLE_SIZES.shipping.includes(numberPart) && useCase == 'shipping') {
          options.size.allSizes[numberPart] = SIZES.allSizes[numberPart]
        } else if (SIZES.allSizes[numberPart] && AVAILABLE_SIZES.storage.includes(numberPart) && useCase == 'storage') {
          options.size.allSizes[numberPart] = SIZES.allSizes[numberPart]
        } else if (SIZES.allSizes[numberPart] && AVAILABLE_SIZES.refrigarated.includes(numberPart) && (useCase == 'coldStorage' || useCase == 'insulatedStorage')) {
          options.size.allSizes[numberPart] = SIZES.allSizes[numberPart]
        } 
      })            
    } else if (purchaseType == 'rental') {
      sizes.forEach(i => {
        const numberPart = i.replace(/\D/g,'')    
        if (SIZES.allSizes[numberPart] && AVAILABLE_SIZES.refrigarated.includes(numberPart)) {
          options.size.allSizes[numberPart] = SIZES.allSizes[numberPart]
        } 
      })
    } else {
      sizes.forEach(i => {
        const numberPart = i.replace(/\D/g,'')    
        options.size.allSizes[numberPart] = SIZES.allSizes[numberPart]
      })
    }
  
  } else {
    options.size = { ...SIZES }
  }

  // Filter by size
  if (size) {
    priceBookEntries = priceBookEntries.filter(i => i.product.size && i.product.size !== 'ODD' && i.product.size.replace(/\D/g,'') == size)
    if (purchaseType === 'sale') {   
      const conditionsArr = []
      priceBookEntries.forEach(i => {
        if (CONDITIONS[i.product.condition]) {
          conditionsArr[Object.keys(CONDITIONS).indexOf(i.product.condition)] = {
            [i.product.condition]: CONDITIONS[i.product.condition]
          }
        }
      })
      conditionsArr.forEach(el => {
        if (el) {
          Object.assign(options.condition, { ...el })
        }
      })
    }
  }
  // Filter by condition
  if (condition) {
    priceBookEntries = priceBookEntries.filter(i => {
      return i.product.condition == condition
    })
  } 
  if (!priceBookEntries.length) {
    delete options.options
  }
 
  return {
    options,
    priceBookEntries,
    originalPriceBookEntries
  }
}
