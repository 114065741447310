import computeOptions from './helpers/options'

export default {
  'options.compute': ({ commit, rootState }) => {
    const { quote: { lead, organization, priceBook }} = rootState
    const { options, priceBookEntries, originalPriceBookEntries } = computeOptions({
      lead,
      organization,
      priceBook,
      commit
    })
    commit('options.set', { options, priceBookEntries, originalPriceBookEntries })
  }
}
