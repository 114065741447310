import actions from './actions'
import mutations from './mutations'
import getters from './getters'

export default {
  namespaced: true,
  actions,
  mutations,
  getters,
  state: {
    visible: false,
    step: 0,
    gtagEvent: null,
    gclid: null,
    options: {},
    priceBook: {},
    priceBookEntries: [],
    originalPriceBookEntries: []
  }
}
