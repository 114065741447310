import { graphqlQuery } from 'shared/graphql'
import { createOrderMutation, updateOrderMutation, updateOpportunityMutation, orderSessionMutation, generateQuotePdfMutation } from 'shared/graphql/mutations'

export const quoteMutations = {
  async create({ lead, organization, zipcode }) {
    const { data: { createOrder } } = await graphqlQuery({
      headers: { 'organization-id': organization.id },
      query: createOrderMutation,
      variables: {
        input: {
          type: 'Quote',
          origin: 'web',
          leadId: lead.id,
          orderType: lead.data.purchaseType || 'sale',
          location: {
            zipcode
          }
        }
      }
    })
    return createOrder
  },
  async update({ quote, organization, variables }) {
    const { data: { updateOrder } } = await graphqlQuery({
      headers: { 'organization-id': organization.id },
      query: updateOrderMutation,
      variables: {
        id: quote.id,
        eventName: 'update',
        ...variables
      }
    })
    return updateOrder
  },
  async getPdfQuote({ organization, variables }) {
    const { data: { downloadPdfOrder } } = await graphqlQuery({
      headers: { 'organization-id': organization.id },
      query: generateQuotePdfMutation,
      variables: {
        ...variables,
        organizationId: organization.id
      }
    })
    return downloadPdfOrder
  }
}

export const orderInitSessionMutation = async function({ organization, variables }) {
  const { data: { sessionOrder } } = await graphqlQuery({
    headers: { 'organization-id': organization.id },
    query: orderSessionMutation,
    variables
  })
  return sessionOrder
}

export const opportunityUpdateMutation = async function({ quote, organization, variables }) {
  const { data: { updateOpportunity } } = await graphqlQuery({
    headers: { 'organization-id': organization.id },
    query: updateOpportunityMutation,
    variables: {
      id: quote.opportunity.id,
      ...variables
    }
  })
  return updateOpportunity
}
