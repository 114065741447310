export default {
  'steps': (state) => {
    return [
      'start',
      ...Object.keys(state.options),
      //'email-quote',
      'checkout',
      'confirmation',
      'customer',
      'quote',
      'thanks'
    ]
  },

  'stepComponent': (state, getters) => {
    return getters.steps[state.step]
  },

  'canGoBack': (state) => {
    return state.step >= 1
  },

  'isComplete': (state, getters) => {
    return [ 'thanks', 'confirmation', 'quote' ].includes(getters.stepComponent)
  }
}
