import { newLead } from './helpers/leads'

export default {
  // lead flow
  'lead.init': (state) => {
    state.lead = state.lead || newLead()
  },
  'lead.load': (state, lead) => {
    state.lead = state.lead || newLead()
    lead.data = lead.data || {}
    Object.assign(state.lead, lead)
  },
  'pricing.load': (state, { zipcode, organization, depot, priceBook }) => {
    if (state.lead) { state.lead.zipcode = zipcode.id.toString() }
    state.city = zipcode.city
    state.organization = organization
    state.depot = depot
    state.priceBook = priceBook
  },
  'states.load': (state, states) => {
    state.states = states | []
  },
  'lead.option.reset': (state, attribute) => {
    if (state.lead.data && Object.prototype.hasOwnProperty.call(state.lead.data, attribute)) {
      state.lead.data[attribute] = null
    }
  },
  'lead.options.update': (state, updates) => {
    Object.assign(state.lead.data, updates)
  },
  // quotes

  'summary.visible': (state, visible) => (state.showSummary = visible),
  'lead.set': (state, lead) => (state.lead = lead),
  'errors.set': (state, errors) => (state.errors = errors),
  'reset': (state) => {
    state.quote = null
    if (state.lead) {
      delete state.lead.id
    }
  },
  'quote.set': (state, quote) => {
    state.quote = quote
    if (!window.location.href.includes('cart')) {
      state.showSummary = !state.flow.visible
    }
  },
  'organization.set': (state, organization) => {
    state.organization = organization
  },
  'price-book.set': (state, { depot, priceBook }) => {
    state.depot = depot
    state.priceBook = priceBook
  },
  'expire.set': (state, lifetime) => {
    state.expiresAt = new Date(Date.now() + lifetime)
  }
}
