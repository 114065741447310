import ahoy from 'ahoy.js'

ahoy.configure({
  urlPrefix: import.meta.env.VITE_API_HOST,
  withCredentials: true
})

if (import.meta.env.NODE_ENV === 'development') {
  window.ahoy = ahoy
  ahoy.debug()
}

export default ahoy
