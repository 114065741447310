import { leadFragment, orderOnQuoteFragment, leadBrief } from './fragments'

export const createLeadMutation = `mutation(
  $input: LeadInput!
  $sync: Boolean
) {
  createLead(
    input: $input
    sync: $sync
  ) {
    ${leadFragment}
    errors {
      message
    }
  }
}`

export const updateLeadMutation = `mutation(
  $id: ID!
  $input: LeadInput!
  $sync: Boolean
) {
  updateLead(
    id: $id
    input: $input
    sync: $sync
  ) {
    ${leadFragment}
    errors {
      message
    }
  }
}`

export const createOrderMutation = `mutation ($input: OrderInput!) {
  createOrder(input: $input) {
    jwt
    order {
      ${orderOnQuoteFragment}
    }
    errors {
      message
    }
  }
}`

export const orderSessionMutation = `mutation ($token: String!) {
  sessionOrder(token: $token) {
    order {
      ${orderOnQuoteFragment}
    }
    lead {
      ${leadBrief}
    }
    organization {
      id
    }
    errors {
      message
    }
  }
}`

export const updateOrderMutation = `mutation ($id: ID!, $eventName: String!, $input: OrderInput!) {
  updateOrder(id: $id, eventName: $eventName, input: $input) {
    order {
      ${orderOnQuoteFragment}
    }
    errors {
      message
    }
  }
}`

export const updateOpportunityMutation = `mutation ($id: ID!, $eventName: String!, $input: OpportunityUpdateInput!) {
  updateOpportunity(id: $id, eventName: $eventName, input: $input) {
    opportunity {
      id
      status
    }
    errors {
      message
    }
  }
}`

export const generateQuotePdfMutation = `mutation ($id: ID!, $organizationId: ID!) {
  downloadPdfOrder(id: $id, organizationId: $organizationId) {            
    pdf                 
  }
}`