import { orderOnQuoteFragment, productFragment, leadBrief } from './fragments'

export const zipQuery = `query(
  $id: ID!
) {
  zipcode(id: $id) {
    id
    city {
      id
      organization {
        id
      }
    }
  }
}`
export const statesQuery = `query {
  states {
    id
    abrv
    name
  }
}`

export const citySearchQuery = `query(
  $query: String
) {
  cities(
    query: $query,
    nameOnly: true,
    first: 10
  ) {
    edges {
      cursor
      node {
        id
        stateId
        name
        organization {
          id
        }
      }
    }
  }
}`

export const geoSearchQuery = `query(
  $coordinates: Coordinate
) {
  zipcodes(
    coordinates: $coordinates,
    first: 1
  ) {
    edges {
      cursor
      node {
        id
        city {
          id
          organization {
            id
          }
        }
      }
    }
  }
}`

export const pricingByZipcode = `query ($zipcode: ID!) {
  pricingByZipcode(zipcode: $zipcode) {
    zipcode {
      id
      cityId
      city {
        id
        name
        stateId
      }
    }
    organization {
      id
      name
      displayName
      email
      phone
    }
    depot {
      id
      name
      distance
      zipcodeId
    }
    priceBook {
      id
      isStandard
      name
      description
      effectiveAt
      effectiveUntil
      priority
      priceVisibility
      entries(classifications: ["container", "container_rental"], website: true) {
        id
        priceCents
        priceVisibility
        estimatedDeliveryTime
        ${productFragment}
      }
    }
  }
}`

export const leadShowQuery = `query(
  $id: ID!
) {
  lead(id: $id) {
    ${leadBrief}
  }
}`


export const orderShowQuery = `query(
  $id: ID!
) {
  order(id: $id) {
    ${orderOnQuoteFragment}
  }
}`

export const orderByReference = `query ($reference: String!, $customerEmail: String!) {
  orderByReference(reference: $reference, customerEmail: $customerEmail) {   
    ${orderOnQuoteFragment}    
  }
}`


export const pricingByProduct = `query ($zipcode: ID!, $productId: ID) {
	pricingByZipcode(zipcode: $zipcode, productId: $productId) {
		zipcode {
			id
      cityId
			city {
				id
				name
				stateId
			}
		}
		organization {
			id
			name
			displayName
			email
			phone
		}
		depot {
      id
			name
			distance
      zipcodeId
		}
		priceBook {
			id
			isStandard
			name
			description
			effectiveAt
			effectiveUntil
			priority
			priceVisibility
		}
		priceBookEntry {
			id
			priceCents
			priceVisibility
			product {
				id
				name
			}
		}
	}
}`

export const solutionByCity = `query(
  $cityId: String!
  $solutionSlug: String!
) {
  solutionByCity(cityId: $cityId, solutionSlug: $solutionSlug) {
    solution {
      slug
    }
    organization {
      id
    }
  }
}`

export const googleLocationToZipcode = `query(
  $googleLocationId: ID!
) {
  googleLocationToZipcode(googleLocationId: $googleLocationId) {
    id
  }
}`