import { graphqlQuery } from 'shared/graphql'
import { orderShowQuery, pricingByZipcode, statesQuery, leadShowQuery, orderByReference as byReference } from 'shared/graphql/queries'

import { leadInput, createLead, updateLead } from './helpers/leads'
import { quoteMutations, opportunityUpdateMutation, orderInitSessionMutation } from './helpers/quotes'

const mergeStateData = (data, state, smsConsent) => {
  const products = (state.flow.priceBookEntries || []).map(i => i.product)
  let mergedData = {
    ...data,
    products,
    priceBookId: state.priceBook && state.priceBook.id,
    priceBookName: state.priceBook && state.priceBook.name,
    organizationId: state.organization && state.organization.id,
    origin: 'web'
  }

  if (smsConsent != undefined) {
    mergedData.smsConsent = smsConsent
  }

  return mergedData
}

export default {

  'pricing.load': async ({ commit, state: { lead } }, zipcode = null) => {
    const { data } = await graphqlQuery({
      query: pricingByZipcode,
      variables: {
        zipcode: zipcode || lead.zipcodeId 
      }
    })
    commit('pricing.load', data.pricingByZipcode)
    if (data.pricingByZipcode.organization) {
      commit('organization.set', data.pricingByZipcode.organization)
    }   
    return data.pricingByZipcode
  },

  'pricing.get': async ({commit, dispatch, state}, {cache = true, zipcode = null} = {}) => {
    if (!cache || !state.expiresAt || new Date(state.expiresAt) < new Date) {
      await dispatch('pricing.load', zipcode)
      commit('expire.set', 5 * 60000)
    }
    return state.priceBook
  },

  'states.load': async ({ commit }) => {
    const { data } = await graphqlQuery({
      query: statesQuery
    })
    commit('states.load', data.states)
    return data.states
  },

  //=================
  // Lead Actions

  'lead.save': async ({ commit, state }, lead) => {
    commit('errors.set', null)
    const input = leadInput({
      ...lead,
      data: mergeStateData(lead.data, state, lead.smsConsent),
      cityId: state.city && state.city.id,
      organizationId: state?.organization?.id,
      gclid: state?.flow?.gclid
    })
    const result = lead.id ? await updateLead(lead.id, input) : await createLead(input)
    if (result.errors && result.errors.length) {
      commit('errors.set', result.errors)
    } else {
      commit('lead.load', result.lead)
      commit('shared/zipcode.set', result.lead.zipcodeId, { root: true })
    }
    return result
  },

  async 'lead.load' ({ commit}, leadId) {
    try {
      const { data: { lead } } = await graphqlQuery({
        query: leadShowQuery,
        variables: {
          id: leadId
        }
      })
      if (lead) {
        commit('lead.set', lead)
      }
      return lead
    } catch (e) {
      console.error(e)
    }
  },
  async 'lead.zipcode.update'({commit, dispatch}, {lead, zipcode}) {
    if (lead.zipcodeId != zipcode) {
      commit('reset')
      commit('flow/reset')
      const zipCodeResult = await dispatch('pricing.load', zipcode)
      lead.zipcodeId = zipcode
      const result = await dispatch('lead.save', {...lead, organizationId: zipCodeResult.organization.id})
      if (result.lead) {
        await dispatch('flow/options.compute')
        return result.lead
      } else {
        return result
      }
    } else {
      return lead
    }
  },

  //=================
  // Quote Actions
  async 'quote.session.init' ({ commit, rootState: { shared } }, variables) {
    const { organization } = shared
    try {
      const result = await orderInitSessionMutation({
        organization,
        variables
      })
      if (result.order) {
        commit('quote.set', result.order)
        if (result.lead) {
          commit('lead.set', result.lead)
        }
        if (result.organization) {
          commit('organization.set', result.organization)
        }
      } else if (result.errors.length) {
        commit('errors.set', result.errors)
      }
      return result.order
    } catch (e) {
      console.error(e)
    }
  },
  async 'quote.load_by_reference' ({ commit, state }, {reference, customerEmail}) {    
    const { organization } = state
    try {
      const { data: { orderByReference }} = await graphqlQuery({
        headers: { 'organization-id': organization.id },
        query: byReference,
        variables: {
          reference: reference,
          customerEmail: customerEmail
        }
      })
      if (orderByReference && Object.keys(orderByReference).length > 0) {
        commit('quote.set', orderByReference)
        return orderByReference
      }    
      
      return false
    } catch (e) {
      console.error(e)
    }
  },
  async 'quote.load' ({ commit, state }, token) {
    const { organization } = state
    try {
      const { data: { order }} = await graphqlQuery({
        headers: { 'organization-id': organization.id },
        query: orderShowQuery,
        variables: {
          token: token
        }
      })
      if (order) {
        commit('quote.set', order)
      }
      return order
    } catch (e) {
      console.error(e)
    }
  },
  async 'quote.save' ({ commit, state, rootState: { shared }}, variables) {
    const { lead, organization, quote } = state
    const { zipcode } = shared
    const fn = quote && quote.id ? 'update' : 'create'
    try {
      const result = await quoteMutations[fn]({
        lead,
        quote,
        organization,
        zipcode,
        variables
      })
      if (result.order) {
        commit('quote.set', result.order)
      } else if (result.errors.length) {
        commit('errors.set', result.errors)
      }
      return result.order
    } catch (e) {
      console.error(e)
    }
  },
  async 'quote.generatePdf' ({ state }, variables) {
    const { organization } = state
    try {
      const result = await quoteMutations['getPdfQuote']({
        organization,        
        variables     
      })
      return result
    } catch (e) {
      console.error(e)
    }
  },
  async 'opportunity.save' ({ commit, state }, variables) {
    const { organization, quote } = state
    try {
      const result = await opportunityUpdateMutation({
        quote,
        organization,
        variables
      })
      if (result.errors.length) {
        commit('errors.set', result.errors)
      }
      return result.opportunity
    } catch (e) {
      console.error(e)
    }
  }
}
