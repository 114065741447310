export default {
  'location-change.accept': (state) => {
    const { shared: { zipcode }} = state

    state.quote.organization =
      state.quote.depoot =
      state.quote.quote =
      state.quote.priceBook = null
    state.quote.flow.priceBookEntries = []
    state.quote.flow.originalPriceBookEntries = []

    if (state.quote.lead) {
      state.quote.lead.id =
        state.quote.lead.zipcodeId = null
      state.quote.lead.zipcode = zipcode
    }
  }
}
