export const leadBrief = `
  id
  email
  firstName
  lastName
  phone
  data
  zipcodeId
  notes
  zipcode {
    id
    cityId
  }
  organization {
    id
    name
    displayName
    email
    phone
  }
`

export const productBrief = `
  id
  code
  slug
  name
  classification
  config
  size
  condition
  rentalType
  primaryPhoto {
    id
    key
  }
`

export const leadFragment = `lead {
  ${leadBrief}
}`

export const productFragment = `product {
  ${productBrief}
  recommendedProducts {
    relatedProduct {
      id
    }
  }
}`

export const orderOnQuoteFragment = `... on Quote {
  id
  type
  status
  priority
  reference
  taxRate
  totalItemsCents
  totalDeliveryCents
  totalTaxCents
  totalCents
  totalQuantity
  distanceInMiles
  updatedAt
  zipcodeId
  zipcode {
    id
    cityId
  }
  opportunity {
    id
    leadId
    closed
  }
  customer {
    ... on Customer {
      id
      companyName
      firstName
      lastName
      email
      phone
    }
    ... on User {
      id
      firstName
      lastName
      email
      phone
    }
  }
  session {
    id
    data
  }
  depot {
    id
    name
    zipcodeId
  }
  priceBook {
    id
    name
    priceVisibility
  }
  items {
    id
    quantity
    priceCents
    priceBookEntry {
      id
      priceCents
      priceVisibility
      ${productFragment}
    }
  }
  deliveryAddress {
    id
    line1
    line2
    locality
    region
    postalCode
  }
}`
