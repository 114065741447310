import flow from './flow'

import actions from './actions'
import mutations from './mutations'
import getters from './getters'

export default {
  modules: {
    flow
  },
  namespaced: true,
  actions,
  mutations,
  getters,
  state: {
    errors: null,
    // lead & quote
    lead: null,
    quote: null,
    // org specific
    organization: null,
    depot: null,
    priceBook: null,
    // logic
    showSummary: false,
    expiresAt: null
  }
}
