import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import createPersistedState from 'vuex-persistedstate'

import shared from './shared'
import quote from './quote'

import mutations from './mutations'
import getters from './getters'

export default new Vuex.Store({
  modules: {
    shared,
    quote
  },
  mutations,
  getters,
  plugins: [createPersistedState({
    key: 'vuex.web',
    paths: [
      'shared.organization',
      'shared.zipcode',
      'shared.area',
      'shared.intention',
      'quote'
    ]
  })]
})
