export default {
  'reset': (state) => {
    state.step = null
    state.visible = false
  },
  'start': (state) => {
    state.step = 0
    state.visible = true
    state.priceBookEntries = []
  },
  'next': (state) => {
    state.step++
    state.priceBookEntries = []
  },
  'back': (state) => {
    if (state.step > 0) {
      state.step--
    }
  },
  'step.set': (state, step) => {
    state.step = step
  },
  'gtag.set': (state, gtagEvent) => {
    state.gtagEvent = gtagEvent
  },
  'gclid.set': (state, gclid) => {
    state.gclid = gclid
  },
  'close': (state) => {
    state.visible = false
  },
  'options.set': (state, { options, priceBookEntries, originalPriceBookEntries }) => {
    state.options = options
    state.priceBookEntries = priceBookEntries
    state.originalPriceBookEntries = originalPriceBookEntries
  }
}
