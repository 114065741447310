import { graphqlQuery } from 'shared/graphql'
import { createLeadMutation, updateLeadMutation } from 'shared/graphql/mutations'

const LEAD_ATTRIBUTES = {
  zipcode: '',
  company: '',
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  notes: '',
  leadSource: 'containeralliance.com',
  data: {
    purchaseType: '',
    useCase: '',
    size: '',
    condition: ''
  },
  gclid: ''
}

export const newLead = () => {
  return {
    ...LEAD_ATTRIBUTES
  }
}

export const leadInput = (lead) => {
  const {
    zipcode,
    cityId,
    firstName,
    lastName,
    email,
    phone,
    company,
    notes,
    leadSource,
    data,
    organizationId,
    gclid
  } = lead
  return {
    zipcode: typeof zipcode === 'string' ? zipcode : typeof zipcode === 'object' ? zipcode.id : null,
    cityId,
    firstName,
    lastName,
    email,
    phone,
    company,
    notes,
    leadSource,
    data,
    organizationId,
    gclid
  }
}

export async function createLead(input) {
  const { errors, data } = await graphqlQuery({
    query: createLeadMutation,
    variables: {
      input,
      sync: false
    }
  })
  if (errors && errors.length) {
    return { errors }
  }
  return data && data.createLead
}

export async function updateLead(id, input) {
  const { data } = await graphqlQuery({
    query: updateLeadMutation,
    variables: {
      id,
      input,
      sync: false
    }
  })
  if (data.errors) {
    return data
  }
  return data && data.updateLead
}
