import Cookies from 'js-cookie'

export default {
  'zipcode.set': (state, zipcode) => {
    state.zipcode = zipcode
    Cookies.set('zipcode', zipcode)
  },
  'area.set': (state, area) => {
    state.area = area
  },
  'intention.set': (state, intention) => {
    state.intention = intention
  },
  'phone-number.set': (state, phoneNumber) => {
    state.phoneNumber = phoneNumber
  },
  'wizard-enabled.set': (state, enabled) => {
    state.wizardEnabled = enabled
  },
  'organization.set': (state, organization) => {
    state.organization = organization
    state.phoneNumber = organization.phone
  }
}
