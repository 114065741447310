import mutations from './mutations'

export default {
  namespaced: true,
  mutations,
  state: {
    organization: null,
    zipcode: null,
    area: null,
    intention: null,
    phoneNumber: '866-533-5094',
    wizardEnabled: false
  }
}
