import Vue from 'vue'
import store from 'shared/store'



import { Tooltip, Collapse } from 'buefy'
Vue.use(Tooltip)
Vue.use(Collapse)



export default function (map) {
  const apps = document.querySelectorAll('[data-vue-app]')
  apps.forEach((el) => {
    const name = el.getAttribute('data-vue-app')
    const app = map[name]
    if (!app) {
      return
    }
    const props = JSON.parse(el.getAttribute('data-props') || '{}')
    if (app) {
      new Vue({
        name,
        store,
        render: h => h(app, { props })
      }).$mount(el)
    }
  })
}
